import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { getLogoProp } from 'utils';
import { baseUrl } from 'common/config';
import axios from 'axios';
import { createPromise } from 'utils/action';
import { View, Image, Button } from '@zhike/ti-ui';
import { Modal } from '@zhike/ti-component';
import { getAIPigai } from 'common/apis';
import cookie from 'cookie';
import { pigaiTypeMap } from 'components/report/mock/index'
import styles from './styles';

// 页面头部
class HeaderContainer extends Component {
  // 参数
  static defaultProps = {
    duration: '',
    showButtons: [],
    unavailableButtons: [],
    getUploadSignature: undefined,
    postCorrectionImage: undefined,
    postCorrection: undefined,
    step: {},
    subjectId: 5,
    logo: {},
  };

  static propTypes = {
    title: PropTypes.string.isRequired,
    showButtons: PropTypes.array,
    unavailableButtons: PropTypes.array,
    duration: PropTypes.string,
    getUploadSignature: PropTypes.func,
    postCorrectionImage: PropTypes.func,
    postCorrection: PropTypes.func,
    step: PropTypes.object,
    subjectId: PropTypes.number,
    match: PropTypes.object.isRequired,
    logo: PropTypes.object,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      machinePigaiStatus: '',
      aiPigaiStatus: '',
    };
    this.timeInterval = undefined;
  }

  // 模块加载
  componentDidMount() {
    const { title } = this.props;
    global.document.title = `${title || ''} - 智课`;
  }

  async submitMachineCorrect(subjectId, answerId) {
    const { postMachinePigai } = this.props;
    await createPromise(postMachinePigai, {
      subject: subjectId === 8 ? 'speaking' : 'writing',
      answerId,
    });
    global.location.reload();
  }

  // 判断外教批改状态
  handleForeignCorrect = () => {
    const { step } = this.props;
    let foreignCorrectImage = require('components/report/assets/foreign_correct.png');
    if (['Committed', 'Reviewing'].indexOf(step.manualPigaiStatus) !== -1) {
      foreignCorrectImage = require('components/report/assets/correct.gif');
    } else if (['Marked', 'Read'].indexOf(step.manualPigaiStatus) !== -1) {
      foreignCorrectImage = require('components/report/assets/foreign_correct_success.png');
    }
    return foreignCorrectImage;
  }

  handleMachine = type => {
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const token = tokenUrl || '';
    const { machinePigaiStatus, aiPigaiStatus } = this.state;
    const { step } = this.props;
    // TODO
    const name = get(step, 'question.name', '批改报告');
    const answerId = get(step.question, 'materials.0.userAnswer.id');
    const { subjectId } = step.question;
    if (!aiPigaiStatus || machinePigaiStatus === 'NoAnswer' || aiPigaiStatus === 'NoAnswer') {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '知道了', style: styles.pay, onClick: undefined },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/default.png')}
            />
            <View className={styles.modalAlertText}>
              你尚未作答，不支持批改哦~
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });
    } else if (aiPigaiStatus === 'Marked') {
      pigaiTypeMap[type].openReportFunc({ subjectId, answerId, name, token })
    } else if (aiPigaiStatus === 'Committed') {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '知道了', style: styles.pay, onClick: undefined },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/success.png')}
            />
            <View className={styles.modalAlertText}>
              提交成功，小智正在努力批改中...
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });
    } else if (aiPigaiStatus === null || aiPigaiStatus === undefined) {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '立即批改', style: styles.pay, onClick: () => pigaiTypeMap[type].submitFunc({ subjectId, answerId: step.answerId }) },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/success.png')}
            />
            <View className={styles.modalAlertText}>
              你还没有提交免费批改哦~
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });
    } else if (aiPigaiStatus === 'Failed') {
      pigaiTypeMap[type].submitFunc({ subjectId, answerId: step.answerId });
      // Modal.show('ModalAlert', {
      //   title: '提示',
      //   width: 400,
      //   buttons: [
      //     { title: '取消', style: styles.close, onClick: undefined, class: 'gray' },
      //     { title: '重新批改', style: styles.pay, onClick: () => this.submitMachineCorrect(subjectId, answerId) },
      //   ],
      //   component: (
      //     /* eslint-disable */
      //     <View className={styles.modalAlert}>
      //       <Image
      //         className={styles.modalAlertImage}
      //         src={require('components/assets/fail.png')}
      //       />
      //       <View className={styles.modalAlertText}>
      //         <span>Oops！批改失败了，点击<span style={{ color: '#39f' }}>重新批改</span>试试~</span>
      //       </View>
      //     </View>
      //     /* eslint-enable */
      //   ),
      //   isReport: true,
      // });
    }
  }

  async handleGetMachineStatus(subject, answerId) {
    let token;
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    if (tokenUrl) {
      token = tokenUrl;
    } else {
      const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
      token = user.token; // eslint-disable-line
    }
    const pigaiData = await axios({
      url: `${getAIPigai[1].replace(/@subject/, subject)}?answerId=${answerId}&token=${token}`,
      headers: {
        From: 1,
      },
      method: 'GET',
    });
    const machinePigaiStatus = get(pigaiData, 'data.data.machinePigaiStatus');
    const aiPigaiStatus = get(pigaiData, 'data.data.aiPigaiStatus');
    if (machinePigaiStatus !== this.state.machinePigaiStatus || aiPigaiStatus !== this.state.aiPigaiStatus) {
      this.setState({ machinePigaiStatus, aiPigaiStatus });
    }
  }

  generateAIModel() {
    const { aiPigaiStatus } = this.state;
    const { question } = this.props.step;
    const answerId = get(question, 'materials.0.userAnswer.id');
    const { subjectId } = question;
    const subject = subjectId === 8 ? 'speaking' : 'writing';
    if (aiPigaiStatus === '' && !this.timeInterval) {
      this.handleGetMachineStatus(subject, answerId);
    }
    let machineCorrectImage;
    if (aiPigaiStatus === 'Committed') {
      machineCorrectImage = require('components/report/assets/correct.gif');
      if (!this.timeInterval) {
        this.timeInterval = setInterval(() => {
          this.handleGetMachineStatus(subject, answerId);
        }, 5000);
      }
    } else if (aiPigaiStatus === 'Marked') {
      machineCorrectImage = require('components/report/assets/machine_correct_success.png');
      if (this.timeInterval) {
        clearInterval(this.timeInterval);
        this.timeInterval = undefined;
      }
    } else {
      if (this.timeInterval) {
        clearInterval(this.timeInterval);
        this.timeInterval = undefined;
      }
      machineCorrectImage = require('components/report/assets/machine_correct.png');
    }
    return (
      <Button
        className={[styles.button, styles.correctBtn]}
        leftIcon={machineCorrectImage}
        text="AI批改"
        onClick={() => this.handleMachine('ai')}
      />);
  }

  // 渲染
  render() {
    const { title, showButtons, unavailableButtons, duration,
      postCorrectionImage, postCorrection, step, match, subjectId, getUploadSignature, logo } = this.props;
    const { exerciseId, mode } = match.params;
    const search = global.location.search; // eslint-disable-line
    const source = decodeURIComponent(search.match(new RegExp('[?#&]source=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const adaptation = decodeURIComponent(search.match(new RegExp('[?#&]adaptation=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const canIntensiveListening = get(step, 'practice.canIntensiveListening');
    const canFollowUp = get(step, 'practice.canFollowUp');
    const practiceId = get(step, 'practice.id');
    const logoProp = getLogoProp({ image: logo.data.image, styles });
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const token = tokenUrl || '';
    let name;
    let answerId;
    let isEmpty;
    if (mode === 'question') {
      isEmpty = get(step, 'question.materials.0.userAnswer.answer.isEmpty');
      name = get(step, 'question.name');
      answerId = get(step, 'question.materials.0.userAnswer.id');
    } else {
      isEmpty = get(step, 'practice.questions.0.materials.0.userAnswer.machinePigaiStatus') === 'NoAnswer';
      name = get(step, 'practice.name');
      answerId = get(step, 'practice.questions.0.materials.0.userAnswer.id');
    }
    return (
      <View className={styles.container}>
        <View className={styles.title}>
          <Image {...logoProp} />
          <View className={styles.titleSplit} />
          <View className={styles.titleText}>
            {title}
          </View>
        </View>

        <View className={styles.buttons}>
          <View className={styles.normalButtons}>
            {
              duration &&
              duration !== '0s' &&
              exerciseId > 0 &&
              <View className={styles.duration}>总耗时：{duration}</View>
            }
            {
              showButtons.indexOf('machine') !== -1 &&
              exerciseId > 0 &&
                this.generateAIModel()
            }
            {
              showButtons.indexOf('foreign') !== -1 &&
              (adaptation === '1' || source !== 'smart') &&
              //subjectId !== 8 &&
              exerciseId > 0 &&
              <Button
                className={[styles.button, styles.correctBtn]}
                leftIcon={this.handleForeignCorrect()}
                isAvailable={unavailableButtons.indexOf('foreign') === -1}
                text="外教批改"
                onClick={() => {
                  if (!answerId || isEmpty) {
                    Modal.show('ModalAlert', {
                      title: '提示',
                      width: 400,
                      buttons: [
                        { title: '知道了', style: styles.pay, onClick: undefined },
                      ],
                      component: (
                        /* eslint-disable */
                        <View className={styles.modalAlert}>
                          <Image
                            className={styles.modalAlertImage}
                            src={require('components/assets/default.png')}
                          />
                          <View className={styles.modalAlertText}>
                            你尚未作答，不支持批改哦~
                          </View>
                        </View>
                        /* eslint-enable */
                      ),
                      isReport: true,
                    });
                  } else {
                    let correctURL = `/correct/${subjectId}/${exerciseId}?title=${`${name || ''} - 智课`}&token=${token}&answerId=${answerId}`
                    if (source !== '') {
                      correctURL = `${correctURL}&source=${source}`
                    }
                    global.window.open(correctURL);
                  }
                }}
              />
            }
            {
              canFollowUp &&
              source !== 'smart' &&
              exerciseId > 0 &&
              <Button
                className={styles.button}
                leftIcon={require('./assets/follow_up.png')}
                text="跟读训练"
                onClick={() => {
                  global.location.href = `${baseUrl}/repeat/${practiceId}${search}`;
                }}
              />
            }
            {
              canIntensiveListening &&
              source !== 'smart' &&
              exerciseId > 0 &&
              <Button
                className={[styles.button, styles.listenBtn]}
                leftIcon={require('./assets/listen.png')}
                text="精听训练"
                onClick={() => {
                  global.location.href = `${baseUrl}/listen/${practiceId}${search}`;
                }}
              />
            }
            {
              showButtons.indexOf('correct') !== -1 &&
              <Button
                className={styles.button}
                leftIcon={require('./assets/correct.png')}
                isAvailable={unavailableButtons.indexOf('correct') === -1}
                text="我要纠错"
                onClick={() =>
                  Modal.show('ModalCorrect', {
                    title: '我要纠错',
                    width: 700,
                    getUploadSignature,
                    postCorrectionImage,
                    postCorrection,
                    step,
                    isReport: true,
                    option: {
                      version: '1.0.0',
                      source: 'ti-ielts',
                    },
                  })
                }
              />
            }
          </View>
          <Modal ref={modal => { Modal.instance = modal; }} />
        </View>
      </View>
    );
  }
}
export default connect(state => ({ logo: state.logo }))(HeaderContainer);
