import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNumber, isNaN, filter, get, meanBy, flatMap } from 'lodash';
import { connect } from 'react-redux';
import axios from 'axios';
import cookie from 'cookie';
import ReactEcharts from 'echarts-for-react';
import { getAIPigai, postAIPigai } from 'common/apis';
import { View, Button, Image, Scrollbar } from '@zhike/ti-ui';
import { getLogoProp } from 'utils';
import { createPromise } from 'utils/action';
import { Article, Modal } from '@zhike/ti-component';
import styles from './styles';

const history = {
  push(url) {
    window.open(url);
  },
};

const subjectList = [
  { id: 5, name: 'Listening' },
  { id: 6, name: 'Reading' },
  { id: 7, name: 'Writing' },
  { id: 8, name: 'Speaking' },
];

async function submitAIPigai({ subjectId, answerId }) {
  let url
  let subject
  if (Number(subjectId) === 8) {
    subject = 'speaking'
    url = postAIPigai[1].replace('@subject', subject)
  } else if (Number(subjectId) === 7) {
    subject = 'writing'
    url = postAIPigai[1].replace('@subject', subject)
  }
  await axios({
    url,
    method: postAIPigai[0],
    headers: {
      'Content-Type': 'application/json',
      From: 1,
    },
    data: {
      subject,
      answerId,
    },
  });
  global.location.reload();
}

// 提交机器批改
async function submitMachineCorrect({ subjectId, answerId }) {
  const { postMachinePigai } = this.props;
  await createPromise(postMachinePigai, {
    subject: subjectId === 8 ? 'speaking' : 'writing',
    answerId,
  });
  global.location.reload();
}

export const pigaiTypeMap = {
  ai: {
    submitFunc: submitAIPigai,
    openReportFunc({ subjectId, answerId, name }) {
      global.window.open(`/machine-correctv2/${subjectId}/${answerId}?title=${name}`);
    },
    statusKey: 'aiPigaiStatus',
  },
  machine: {
    submitFunc: submitMachineCorrect,
    openReportFunc({ subjectId, answerId, name, token }) {
      global.window.open(`/machine-correct/${subjectId}/${answerId}?title=${name}&token=${token}`);
    },
    statusKey: 'machinePigaiStatus',
  },
}

function Cylindricity({ name, num, selectSubjectId }) {
  let title;
  let height = 0;
  if (selectSubjectId < 7) {
    title = `${parseInt(Math.round(num * 100), 10)}%`;
    height = `${num * 100}%`;
  } else {
    title = num;
    height = `${(num / 9) * 100}%`;
  }
  return (
    <View key={name} className={[styles.cylindricityBox, selectSubjectId === 8 && styles.marginLeft80]}>
      {title}
      <View className={styles.cylindricity}>
        <View className={styles.cylindricityGreen} style={{ height }} />
      </View>
      {name}
    </View>
  );
}

function StatLeft({
  selectSubjectId,
  subjectPractices,
  speakingStepList,
  writingStepList,
}) {
  // 生成柱形图
  let dataLeftHtml;
  if (selectSubjectId === 5 || selectSubjectId === 6) {
    dataLeftHtml = subjectPractices.map((item, index) => {
      // const subjectPractice = filter(practices, { id: item })[0];
      const { questionNum, correctNum } = item;
      const name = `Part ${index + 1}`;
      const num = correctNum / questionNum;
      return <Cylindricity name={name} num={num} selectSubjectId={selectSubjectId} />;
    });
  } else if (selectSubjectId === 7 || selectSubjectId === 8) {
    dataLeftHtml = subjectPractices.slice(0, 3).map((item, index) => {
      const name = `Part ${index + 1}`;
      const num = isNaN(item.aiPigaiScore) ? 0 : item.aiPigaiScore;
      return <Cylindricity name={name} num={num} selectSubjectId={selectSubjectId} />;
    });
  }
  const titleList = ['', '听力正确率', '阅读正确率', '写作得分', '口语得分'];
  // const selectSubjectImgId = selectSubjectId; // 判断是否是新模考，来显示不同的图片
  // TODO
  // if (textbookId === 193 && selectSubjectId === 2) selectSubjectImgId = 5;
  // if (textbookId === 193 && selectSubjectId === 3) selectSubjectImgId = 6;
  return (
    <View className={styles.dataLeft}>
      <View className={styles.dataTitle}>{titleList[selectSubjectId - 4]}</View>
      <View className={styles.dataLeftContent}>
        <View className={styles.cylindricityContent}>{dataLeftHtml}</View>
      </View>
    </View>
  );
}

function MaterialsCorrect({ materials, exerciseId, tag }) {
  return (
    <View className={styles.block}>
      <View className={styles.materialsCorrect}>
        {
          materials.map(material => {
            const { qNum, id, userAnswer = {}, answer } = material;
            const isMulti = qNum.length > 1
            return qNum.map((num, index) => {
              let isCorrect = userAnswer.correct
              if (isMulti) {
                const uAnswer = get(userAnswer, ['answer', index])
                if ((answer || []).includes(uAnswer)) {
                  isCorrect = true
                }
              }
              const correct = isCorrect ? styles.color49cf51 : styles.colorfd5454;
              return (
                <View
                  className={[styles.num, correct]}
                  key={`${id}-${num}`}
                  onClick={
                    () => {
                      history.push(`/report/mock/${exerciseId}/${id}`);
                    }
                  }
                >
                  {num}
                </View>
              )
            });
          })
        }
      </View>
      {
        tag &&
        <View className={styles.tag} style={{ marginTop: '16px' }}>
          {tag}
        </View>
      }
    </View>
  );
}

function SpeakingRadar({ speakingStepList }) {
  const fluencyCoherence = meanBy(speakingStepList, step => get(step, 'aiPigaiResult.fluencyCoherence.score'));
  const grammaticalRangeAndAccuracy = meanBy(speakingStepList, 'aiPigaiResult.grammaticalRangeAndAccuracy.score');
  const lexicalResources = meanBy(speakingStepList, 'aiPigaiResult.lexicalResources.score');
  const pronunciation = meanBy(speakingStepList, 'aiPigaiResult.pronunciation.score');
  const speakingOption = {
    radar: {
      shape: 'circle',
      silent: true,
      startAngle: 45,
      splitArea: {
        areaStyle: { color: ['#fff'] },
      },
      name: {
        color: '#505050',
      },
      indicator: [
        { name: 'Fluency &\n Coherence', max: 9 },
        { name: 'Lexical Complexity', max: 9 },
        { name: 'Pronunciation', max: 9 },
        { name: 'Grammatical Range \n and Accuracy', max: 9 },
      ],
    },
    series: [{
      type: 'radar',
      data: [
        {
          value: [
            fluencyCoherence,
            lexicalResources,
            pronunciation,
            grammaticalRangeAndAccuracy,
          ],
        },
      ],
      symbol: 'roundRect',
      itemStyle: {
        normal: {
          borderColor: '#FF8E04',
          borderWidth: 6,
          shadowColor: '#FF8E04',
          shadowBlur: 10,
          opacity: 1,
        },
      },
      lineStyle: {
        normal: {
          opacity: 0.5,
          color: '#FF8E04',
        },
      },
    }],
  };
  return <ReactEcharts option={speakingOption} style={{ height: '232px', width: '500px', margin: '0 auto' }} />;
}

function CalculateWritingScore(writingStepList, attribute) {
  const score = Math.round((get(writingStepList[0], attribute) + (get(writingStepList[1], attribute) * 2)) / 3 * 2) / 2 / 10
  return score
}
function WritingRadar({ writingStepList }) {
  let coherenceCohesion
  let grammaticalRangeAndAccuracy
  let lexicalResources
  let taskResponse
  if (writingStepList.length === 2 && writingStepList[0].aiPigaiStatus === 'Marked' && writingStepList[1].aiPigaiStatus === 'Marked') {
    coherenceCohesion = CalculateWritingScore(writingStepList, 'aiPigaiResult.coherenceCohesion.score')
    grammaticalRangeAndAccuracy = CalculateWritingScore(writingStepList, 'aiPigaiResult.grammaticalRangeAndAccuracy.score')
    lexicalResources = CalculateWritingScore(writingStepList, 'aiPigaiResult.lexicalResources.score')
    taskResponse = CalculateWritingScore(writingStepList, 'aiPigaiResult.taskResponse.score')
  } else {
    coherenceCohesion = meanBy(writingStepList, 'aiPigaiResult.coherenceCohesion.score') / 10;
    grammaticalRangeAndAccuracy = meanBy(writingStepList, 'aiPigaiResult.grammaticalRangeAndAccuracy.score') / 10;
    lexicalResources = meanBy(writingStepList, 'aiPigaiResult.lexicalResources.score') / 10;
    taskResponse = meanBy(writingStepList, 'aiPigaiResult.taskResponse.score') / 10;
  }
  console.log(coherenceCohesion, grammaticalRangeAndAccuracy, writingStepList);
  const writingOption = {
    radar: {
      shape: 'circle',
      silent: true,
      splitNumber: 5,
      startAngle: 45,
      splitArea: {
        areaStyle: { color: ['#fff'] },
      },
      name: {
        color: '#505050',
      },
      indicator: [
        { name: 'Coherence &\n Cohesion', max: 1 },
        { name: 'Lexical Complexity', max: 1 },
        { name: 'Task Response', max: 1 },
        { name: 'Grammatical Range\n and Accuracy', max: 1 },
      ],
    },
    series: [{
      type: 'radar',
      data: [
        {
          value: [
            coherenceCohesion,
            lexicalResources,
            taskResponse,
            grammaticalRangeAndAccuracy,
          ],
          name: '预算分配（Allocated Budget）',
        },
      ],
      symbol: 'roundRect',
      itemStyle: {
        normal: {
          borderColor: '#FF8E04',
          borderWidth: 6,
          shadowColor: '#FF8E04',
          shadowBlur: 10,
          opacity: 1,
        },
      },
      lineStyle: {
        normal: {
          color: '#FF8E04',
        },
      },
    }],
  };
  return <ReactEcharts option={writingOption} style={{ height: '232px', width: '400px', margin: '0 auto' }} />;
}

// 阅读题目
class Reports extends Component {
  // 参数
  static propTypes = {
    report: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    reportStepList: PropTypes.array.isRequired,
    postMachinePigai: PropTypes.func.isRequired,
    logo: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      speakingStepList: [],
      writingStepList: [],
      selectSubjectId: get(props, 'report.subjects.0.subjectId') || 1,
      isCheckError: false,
      isScroll: false,
      selectQuestionTag: '全部',
      hover: 0,
    };
    this.timeInterval = null;
    this.tabs = null;
    this.dataRightContent = null;
  }

  componentDidMount() {
    // 请求数据
    const { reportStepList } = this.props;
    const speakingStepList = filter(reportStepList, { subjectId: 8 });
    const writingStepList = filter(reportStepList, { subjectId: 7 });
    this.setState({ speakingStepList, writingStepList });
    if (speakingStepList.length > 0 || writingStepList.length > 0) {
      this.timeInterval = setInterval(() => {
        this.handleMachinePigai();
      }, 5000);
    }
    // 监听页面滚动
    global.window.addEventListener('scroll', this.monitorScroll);
    // 如果有subjectId，当前subjec选中
    const search = global.location.search; // eslint-disable-line
    const subjectId = decodeURIComponent(search.match(new RegExp('[?#&]subjectId=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    if (subjectId) this.setState({ selectSubjectId: parseInt(subjectId, 10) });
  }

  // 模块卸载
  componentWillUnmount() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
      this.timeInterval = null;
    }
    global.window.removeEventListener('loadedmetadata', this.monitorScroll);
  }

  // 页面滚动触发事件
  monitorScroll = () => {
    if (this.tabs) {
      const scrollTop = global.document.documentElement.scrollTop || global.document.body.scrollTop;
      const tabsTop = 556;
      const { isScroll } = this.state;
      if (scrollTop < tabsTop && isScroll) {
        this.setState({ isScroll: false });
      }
      if (scrollTop > tabsTop && !isScroll) {
        this.setState({ isScroll: true });
      }
    }
  }

  // 处理写作和口语机器批改状态，分数
  async handleMachinePigai() {
    const { speakingStepList, writingStepList } = this.state;
    let isSpeakingPost = 0;
    let isWritingPost = 0;
    let token;
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    if (tokenUrl) {
      token = tokenUrl;
    } else {
      const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
      token = user.token; // eslint-disable-line
    }
    for (let i = 0; i < speakingStepList.length; i += 1) {
      if (speakingStepList[i].answerId && ['Reviewing', 'Committed', 'Failed'].indexOf(speakingStepList[i].aiPigaiStatus) !== -1) {
        const pigaiData = await axios({
          url: `${getAIPigai[1].replace(/@subject/, 'speaking')}?answerId=${speakingStepList[i].answerId}&token=${token}&tranResult=1`,
          headers: {
            From: 1,
          },
          method: 'GET',
        });
        if (['Marked', 'Read'].indexOf(pigaiData.data.data.aiPigaiStatus) !== -1) {
          speakingStepList[i].aiPigaiStatus = pigaiData.data.data.aiPigaiStatus;
          speakingStepList[i].aiPigaiScore = pigaiData.data.data.aiPigaiScore;
          speakingStepList[i].aiPigaiResult = pigaiData.data.data.aiPigaiResult;
          global.location.reload();
        } else {
          isSpeakingPost += 1;
        }
      }
    }
    for (let k = 0; k < writingStepList.length; k += 1) {
      if (writingStepList[k].answerId && ['Reviewing', 'Committed', 'Failed'].indexOf(writingStepList[k].aiPigaiStatus) !== -1) {
        const pigaiData = await axios({
          url: `${getAIPigai[1].replace(/@subject/, 'writing')}?answerId=${writingStepList[k].answerId}&token=${token}&tranResult=1`,
          headers: {
            From: 1,
          },
          method: 'GET',
        });
        if (['Marked', 'Read'].indexOf(pigaiData.data.data.aiPigaiStatus) !== -1) {
          writingStepList[k].aiPigaiStatus = pigaiData.data.data.aiPigaiStatus;
          writingStepList[k].aiPigaiScore = pigaiData.data.data.aiPigaiScore;
          writingStepList[k].aiPigaiResult = pigaiData.data.data.aiPigaiResult;
          global.location.reload();
        } else {
          isWritingPost += 1;
        }
      }
    }
    this.setState({ speakingStepList, writingStepList });
    // 如果不断请求批改状态，并且批改成功的话，则刷新之
    if (isSpeakingPost === 0 && isWritingPost === 0) {
      clearInterval(this.timeInterval);
      this.timeInterval = null;
      return false;
    }
  }

  // 成绩组件
  generateScore = () => {
    const { report } = this.props;
    const { speakingStepList, writingStepList, hover } = this.state;
    let readingScore;
    let listeningScore;
    let speakingScore = 0;
    let writingScore = 0;
    let totalScore;
    const selectSubjects = [];
    if (report) {
      const { subjects } = report;
      for (let i = 0; i < subjects.length; i += 1) {
        if (subjects[i].subjectId === 6) {
          readingScore = subjects[i].score;
          selectSubjects.push(6);
        } else if (subjects[i].subjectId === 5) {
          listeningScore = subjects[i].score;
          selectSubjects.push(5);
        } else if (subjects[i].subjectId === 8) {
          selectSubjects.push(8);
          let speakingScoreSum = 0
          let pigaiCompleteNum = 0
          for (let k = 0; k < speakingStepList.length; k += 1) {
            if (speakingStepList[k].aiPigaiStatus === 'Marked') {
              speakingScoreSum += get(speakingStepList, [k, 'aiPigaiScore'], 0)
              pigaiCompleteNum += 1
            } else if (speakingStepList[k].aiPigaiStatus === 'Uncommitted') {
              speakingScore = '未提交';
              break;
            } else if (speakingStepList[k].aiPigaiStatus === 'Committed') {
              speakingScore = '批改中';
              break;
            } else if (speakingStepList[k].aiPigaiStatus === 'Failed') {
              speakingScore = '等待批改';
              break;
            } else {
              // speakingScore = '未作答';
              // break;
            }
          }
          if (isNumber(speakingScore) && pigaiCompleteNum !== 0) {
            speakingScore = Math.round((speakingScoreSum) / pigaiCompleteNum * 2) / 2;
          }
        } else if (subjects[i].subjectId === 7) {
          selectSubjects.push(7);
          let writingScoreSum = 0
          let pigaiCompleteNum = 0
          // 大小作文按 1/3 2/3的权重
          if (writingStepList.length === 2 && writingStepList[0].aiPigaiStatus === 'Marked' && writingStepList[1].aiPigaiStatus === 'Marked') {
            writingScore = Math.round((writingStepList[0].aiPigaiScore + (writingStepList[1].aiPigaiScore * 2)) / 3 * 2) / 2
          } else {
            // 万一有多个题目，计算平均分(沿用旧的机器批改的逻辑)
            for (let j = 0; j < writingStepList.length; j += 1) {
              if (writingStepList[j].aiPigaiStatus === 'Marked') {
                writingScoreSum += get(writingStepList, [j, 'aiPigaiScore'], 0)
                pigaiCompleteNum += 1
              } else if (writingStepList[j].aiPigaiStatus === 'Uncommitted') {
                writingScore = '未提交批改';
                break;
              } else if (writingStepList[j].aiPigaiStatus === 'Committed') {
                writingScore = '批改中';
                break;
              } else if (writingStepList[j].aiPigaiStatus === 'Failed') {
                writingScore = '等待批改';
                break;
              } else {
                // writingScore = '未作答';
                // break;
              }
              if (isNumber(writingScore) && pigaiCompleteNum !== 0) {
                writingScore = Math.round((writingScoreSum) / pigaiCompleteNum * 2) / 2;
              }
            }
          }
        }
      }

      // if (isNumber(speakingScore)) speakingScore = speakingScore;
      // if (isNumber(writingScore)) writingScore = writingScore;
      if (subjects.length === 4) {
        totalScore = Math.round((readingScore + listeningScore + speakingScore + writingScore) / 4 * 2) / 2;
      }
    }

    // 判断总分匹配不同的奖杯
    let trophy = 5;

    // 金银铜铁四种徽章
    if (isNumber(totalScore) && !isNaN(totalScore)) {
      if (totalScore <= 9) trophy = 1;
      if (totalScore < 7) trophy = 2;
      if (totalScore < 6.5) trophy = 3;
      if (totalScore < 5.5) trophy = 4;
    }

    return (
      <View className={[styles.whiteBox, styles.score]}>
        <View className={styles.scoreLeft}>
          <Image
            className={styles.scoreTrophy}
            src={require(`./assets/trophy${trophy}.png`)} // eslint-disable-line
          />
          <View
            className={[styles.scoreNum, (!isNumber(totalScore) || isNaN(totalScore)) && styles.scoreNum2]}
          >{isNumber(totalScore) && !isNaN(totalScore) ? totalScore : '暂无得分'}
          </View>
          <View className={styles.scoreText}>Total Score</View>
        </View>
        <View className={styles.scoreLine} />
        <View className={styles.scoreRight}>
          <View className={styles.scoreItem} style={{ backgroundImage: `url(${require('./assets/score_listening.png')})` }}>
            <View className={styles.scoreText1}>Listening</View>
            <View
              className={[styles.scoreNum1, selectSubjects.indexOf(5) === -1 && styles.scoreNum2]}
            >{selectSubjects.indexOf(5) === -1 ? '未选' : listeningScore}
            </View>
          </View>
          <View className={styles.scoreItem} style={{ backgroundImage: `url(${require('./assets/score_reading.png')})` }}>
            <View className={styles.scoreText1}>Reading</View>
            <View
              className={[styles.scoreNum1, selectSubjects.indexOf(6) === -1 && styles.scoreNum2]}
            >{selectSubjects.indexOf(6) === -1 ? '未选' : readingScore}
            </View>
          </View>
          <View className={styles.scoreItem} style={{ backgroundImage: `url(${require('./assets/score_writing.png')})` }}>
            <View className={styles.scoreText1}>Writing</View>
            <View
              className={[styles.scoreNum1, !isNumber(writingScore) && styles.scoreNum2]}
            >
              {selectSubjects.indexOf(7) === -1 ? '未选' : writingScore}
            </View>
          </View>
          <View className={styles.scoreItem} style={{ backgroundImage: `url(${require('./assets/score_speaking.png')})` }}>
            <View className={styles.scoreText1}>Speaking</View>
            <View
              className={[styles.scoreNum1, !isNumber(speakingScore) && styles.scoreNum2]}
            >
              {selectSubjects.indexOf(8) === -1 ? '未选' : speakingScore}
            </View>
          </View>
        </View>
        <View
          className={styles.questionTip}
          onMouseEnter={() => this.setState({ hover: 1 })}
          onMouseLeave={() => this.setState({ hover: 0 })}
        >
          <View className={[styles.tipContent, hover === 1 && styles.block]}>
            <View className={styles.triangleUp} />
            <View className={styles.tipTitle}>提示</View>
            1.口语和写作得分为智课AI机器人批改结果，分数仅供参考；<br />
            2.你可以选择外教批改服务，专业评分更准确哦；
          </View>
        </View>
      </View>
    );
  }

  // tab组件
  generateTab = () => {
    const { selectSubjectId, isScroll } = this.state;
    const { report } = this.props;
    const { subjects } = report;
    const icons = [
      require('./assets/icon_1.png'),
      require('./assets/icon_2.png'),
      require('./assets/icon_3.png'),
      require('./assets/icon_4.png'),
    ];
    const grayIcons = [
      require('./assets/icon_gray_1.png'),
      require('./assets/icon_gray_2.png'),
      require('./assets/icon_gray_3.png'),
      require('./assets/icon_gray_4.png'),
    ];
    const tabs = subjectList.map((item, index) => {
      let tabEmpty;
      if (filter(subjects, { subjectId: item.id }).length === 0) {
        tabEmpty = styles.opacity5;
      }
      return (
        <View
          key={index}
          className={[
            styles.tabItem,
            selectSubjectId === item.id && styles.tabItemS,
            tabEmpty,
          ]}
          onClick={!tabEmpty ?
            () => {
              global.document.body.scrollTop = 560;
              global.document.documentElement.scrollTop = 560;
              this.setState({
                selectSubjectId: item.id,
                selectQuestionTag: '全部',
                isCheckError: false,
              });
            } : () => { }}
        >
          <Image
            className={styles.tabImage}
            src={selectSubjectId === item.id ? icons[index] : grayIcons[index]}
          />
          {item.name}
        </View>
      );
    });
    return (
      <View ref={ref => { this.tabs = ref; }} className={styles.tab}>
        {tabs}
        {
          isScroll &&
          <View className={styles.scrollTabBox}>
            <View className={styles.scrollTab}>
              {tabs}
            </View>
          </View>
        }
      </View>
    );
  }

  // 数据统计
  generateData = () => (
    <View className={[styles.whiteBox, styles.data]}>
      {this.generateTitle('数据统计')}
      <View className={styles.dataContent}>
        {this.generateDataLeft()}
        <View className={styles.dataLine} />
        {this.generateDataRight()}
      </View>
    </View>
  );

  // 数据统计 左边
  generateDataLeft = () => {
    const { selectSubjectId, speakingStepList, writingStepList } = this.state;
    const { report } = this.props;
    const { subjects } = report;
    const selectSubject = filter(subjects, { subjectId: selectSubjectId })[0];
    const subjectPractices = selectSubject.practicesInfo;
    const data = {
      selectSubjectId,
      speakingStepList,
      writingStepList,
      subjectPractices,
    };
    return <StatLeft {...data} />;
  }
  // 数据统计 右边
  generateDataRight = () => {
    const { selectSubjectId, hover } = this.state;
    const { report } = this.props;
    const { subjects } = report;
    const selectSubject = filter(subjects, { subjectId: selectSubjectId })[0];
    const { questionTags } = selectSubject;
    const newQuestionTags = {};
    questionTags.forEach(item => {
      if (newQuestionTags[item.questionTag]) {
        newQuestionTags[item.questionTag] = {
          correct: item.correctNum + newQuestionTags[item.questionTag].correct,
          sum: item.questionNum + newQuestionTags[item.questionTag].sum,
        };
      } else {
        newQuestionTags[item.questionTag] = {
          correct: item.correctNum,
          sum: item.questionNum,
        };
      }
    });
    const dataRightHtml1 = [];
    const dataRightHtml2 = [];
    const dataRightHtml3 = [];
    if (selectSubjectId === 5 || selectSubjectId === 6) {
      Object.keys(newQuestionTags).forEach((item, index) => {
        const percentage = parseInt(Math.round(newQuestionTags[item].correct / newQuestionTags[item].sum * 100), 10);
        dataRightHtml1.push(<View key={index} className={styles.dataRightText}>{item}</View>);
        dataRightHtml2.push(
          <View className={styles.dataRightblocks} key={index}>
            <View className={[styles.blockItem, percentage > 0 && styles.color1]} />
            <View className={[styles.blockItem, percentage > 20 && styles.color2]} />
            <View className={[styles.blockItem, percentage > 40 && styles.color3]} />
            <View className={[styles.blockItem, percentage > 60 && styles.color4]} />
            <View className={[styles.blockItem, percentage > 80 && styles.color5]} />
          </View>);
        dataRightHtml3.push(<View key={index} className={styles.dataRightText}>{`${percentage}%`}</View>);
      });
      dataRightHtml2.push(
        <View key={40} className={styles.dataRightblocks}>
          <View className={styles.dataRightTip}>很弱</View>
          <View className={styles.dataRightTip}>较弱</View>
          <View className={styles.dataRightTip}>弱</View>
          <View className={styles.dataRightTip}>一般</View>
          <View className={styles.dataRightTip}>良好</View>
        </View>);
    }
    const titleList = ['', '题型正确率', '题型正确率', '写作评分点', '口语评分点'];
    return (
      <View className={styles.dataRight}>
        <View className={styles.dataTitle}>
          {titleList[selectSubjectId - 4]}
          {
            selectSubjectId === 700 &&
            <View
              className={[styles.questionTip, styles.questionTipSmall]}
              onMouseEnter={() => this.setState({ hover: 3 })}
              onMouseLeave={() => this.setState({ hover: 0 })}
            >
              <View className={[styles.tipContent, styles.tipContentSmall, hover === 3 && styles.block]}>
                <View className={styles.triangleLeft} />
                根据ETS考试中心官方给出的TOEFL应试指南，
                口语考试专家通常使用的评分标准包括总体陈述印象Delivery、
                语言运用Language Use、话题发展Topic Development三个方面，
                考察考生在学术环境中有效交谈的能力，比如词汇能力、语法能力、
                口语表达能力及话题展开和内容逻辑的组织能力等。
              </View>
            </View>
          }
          {
            // 先注释掉
            selectSubjectId === 800 &&
            <View
              className={[styles.questionTip, styles.questionTipSmall]}
              onMouseEnter={() => this.setState({ hover: 4 })}
              onMouseLeave={() => this.setState({ hover: 0 })}
            >
              <View className={[styles.tipContent, styles.tipContentSmall, hover === 4 && styles.block]}>
                <View className={styles.triangleLeft} />
                TOEFL写作考试是从语法Grammar、句型结构Style、论证发展Development、
                单词拼写Mechanics、用词Usage、词汇复杂度Lexical Complexity、
                结构布局Organization七个评分点评分，主要考察考生的词汇能力、语法能力、
                文章结构的组织能力和文章内容的展开和组织等综合能力，
                每个评分点的考察项不仅相同，但均围绕以上写作能力进行考察。
              </View>
            </View>
          }
        </View>
        {
          selectSubjectId < 7 &&
          <View ref={ref => { this.dataRightContent = ref; }} className={styles.dataRightContent}>
            <View key={1}>{dataRightHtml1}</View>
            <View key={2}>{dataRightHtml2}</View>
            <View key={3}>{dataRightHtml3}</View>
          </View>
        }
        {
          selectSubjectId === 8 &&
          <View
            ref={ref => { this.dataRightContent = ref; }}
            className={[styles.dataRightContent, styles.marginTop90]}
          >
            <SpeakingRadar speakingStepList={this.state.speakingStepList} />
          </View>
        }
        {
          selectSubjectId === 7 &&
          <View
            ref={ref => { this.dataRightContent = ref; }}
            className={[styles.dataRightContent, styles.marginTop90]}
          >
            <WritingRadar writingStepList={this.state.writingStepList} />
          </View>
        }
      </View>
    );
  }

  // 生成标题
  generateTitle(text) {
    return (
      <View className={styles.bigTitle}>
        <View className={styles.smallCircle} />
        <View className={styles.bigCircle} />
        <View className={styles.bigTitleText}>{text}</View>
        <View className={styles.bigCircle} />
        <View className={styles.smallCircle} />
      </View>
    );
  }

  // 生成作答详情
  generateAnswerDetail() {
    return (
      <View className={[styles.whiteBox, styles.answerDetail]}>
        {this.generateTitle('作答详情')}
        {this.generateTabBody()}
      </View>
    );
  }

  // tabbody组件
  generateTabBody = () => {
    const { selectSubjectId } = this.state;
    const { report } = this.props;
    const { subjects, practices } = report;
    const selectSubject = filter(subjects, { subjectId: selectSubjectId })[0];
    if (selectSubjectId === 5 || selectSubjectId === 6) {
      return this.generateReadingListening(selectSubject, practices);
    } else if (selectSubjectId === 7 || selectSubjectId === 8) {
      return this.generateSpeakingWriting(selectSubject, practices);
    }
  }

  // 处理阅读和听力
  generateReadingListening = (selectSubject, practices) => {
    const { match } = this.props;
    const { selectSubjectId, isCheckError, selectQuestionTag } = this.state;
    const subjectPractices = selectSubject.practices;
    const { questionTags } = selectSubject;
    const { mode, exerciseId } = match.params;
    const search = global.location.search; // eslint-disable-line
    let isAllCorrect = 0;
    const subjectPracticesHtml = subjectPractices.map((item, index) => {
      // const subjectPractice = filter(practices, { id: item })[0];
      const subjectPractice = item;
      const questionNum = item.questionNum;
      const correctQuestionNum = item.correctNum;
      const { topic } = subjectPractice;
      let materialId;
      const questionsHtml = subjectPractice.questions.map(item => {
        if (selectQuestionTag !== '全部' && item.questionTag !== selectQuestionTag) {
          return false;
        }
        materialId = item.materials[0].id;
        let material = { origin: item.stem };
        switch (item.type) {
          case 'Insert':
            material = { origin: item.materials[0].insertSentence };
            break;
          case 'Drag':
            material = { origin: item.materials[0].direction };
            break;
          default:
            break;
        }
        !material.origin && (material.origin = { paragraphs: [] });
        return (
          <View className={styles.numContainer} key={item.id}>
            <MaterialsCorrect question={item} tag={item.questionTag} materials={item.materials} exerciseId={exerciseId} />
          </View>
        );
      });
      // 如果一道题都没有，则不显示
      if (_.every(questionsHtml, x => !x)) {
        return false;
      }
      let title;
      if (selectSubjectId === 1) {
        title = `Passage ${index + 1}`;
      } else {
        title = ['Part 1', 'Part 2', 'Part 3', 'Part 4', 'Part 5', 'Part 6'][index];
      }
      if (questionNum === 0 || (isCheckError && questionNum - correctQuestionNum === 0)) {
        if (isCheckError) isAllCorrect += 1;
        return false;
      }
      return (
        <View key={item.id}>
          <View className={styles.title}>
            {title}
            {topic && topic.name && <View className={styles.topic}>{topic.name}</View>}
            {
              selectQuestionTag === '全部' &&
                <View className={styles.questionNum}>
                  {
                    isCheckError ? '错误题目' : '正确题目'
                  }
                  <View className={styles.correctQuestionScore}>
                    {
                      isCheckError ? `${questionNum - correctQuestionNum}个` : `${correctQuestionNum}/${questionNum}个`
                    }
                  </View>
                </View>
            }
          </View>
          <View className={styles.titleMark} />
          <View key={item.id} className={styles.questionBox}>
            <View className={styles.questionContentReading} key={item.id}>
              {questionsHtml}
            </View>
            <Button
              className={styles.button}
              text="答案解析"
              onClick={() => {
                history.push(`/report/${mode}/${exerciseId}/${materialId}${search}`);
              }
              }
            />
          </View>
        </View>
      );
    });
    return (
      <div>
        {this.generateSearch(questionTags)}
        {isAllCorrect === subjectPracticesHtml.length ?
          <View className={styles.greatBox}>
            <Image className={styles.greatImg} src={require('./assets/great.png')} />
            真棒，此标签下没有错题哦
          </View>
          : subjectPracticesHtml}
      </div>
    );
  }

  // 生成阅读，听力搜索栏
  generateSearch(questionTags) {
    const { selectQuestionTag, isCheckError } = this.state;
    const newQuestionTags = [];
    Array.isArray(questionTags) && questionTags.forEach(item => {
      if (newQuestionTags.indexOf(item.questionTag) === -1) {
        newQuestionTags.push(item.questionTag);
      }
    });
    return (
      <View className={styles.searchBox}>
        <View className={styles.searchContent}>
          <View
            className={[styles.searchItem, selectQuestionTag === '全部' && styles.color385DAE]}
            onClick={() => this.setState({ selectQuestionTag: '全部' })}
          >全部
          </View>
          {
            newQuestionTags.map((item, index) =>
              (
                <View
                  key={index}
                  className={[styles.searchItem, selectQuestionTag === item && styles.color385DAE]}
                  onClick={() => this.setState({ selectQuestionTag: item })}
                >
                  {item}
                </View>
              ))
          }
        </View>
        {
          selectQuestionTag !== '全部' ||
          <View className={styles.stripBox}>
            <View
              className={[styles.strip, isCheckError && styles.stripActive]}
              onClick={() => { this.setState({ isCheckError: !isCheckError }); }}
            >
              <View className={[styles.round, isCheckError && styles.roundActive]} />
            </View>
            <View className={styles.stripText}>
              只看错题
            </View>
          </View>
        }
      </View>
    );
  }

  // 判断外教批改状态
  handleForeignCorrect = () => {
    const { report: step } = this.props;
    let foreignCorrectImage = require('components/report/assets/foreign_correct.png');
    if (['Committed', 'Reviewing'].indexOf(step.manualPigaiStatus) !== -1) {
      foreignCorrectImage = require('components/report/assets/correct.gif');
    } else if (['Marked', 'Read'].indexOf(step.manualPigaiStatus) !== -1) {
      foreignCorrectImage = require('components/report/assets/foreign_correct_success.png');
    }
    return foreignCorrectImage;
  }


  // 处理口语和写作
  generateSpeakingWriting = (selectSubject, practices) => {
    const { match, report } = this.props;
    const { selectSubjectId, speakingStepList, writingStepList } = this.state;
    const subjectPractices = selectSubject.practices;
    const { mode, exerciseId } = match.params;
    const search = global.location.search; // eslint-disable-line
    const source = decodeURIComponent(search.match(new RegExp('[?#&]source=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const token = tokenUrl || '';

    return subjectPractices.map((item, index) => {
      // const subjectPractice = filter(practices, { id: item })[0];
      const subjectPractice = item;
      const title = `Part ${index + 1}`;
      const { topic, name } = subjectPractice;
      const questionsHtml = subjectPractice.questions.map((item, index) => {
        const materialId = item.materials[0].id;
        let score;
        let step;
        let icon;
        if (selectSubjectId === 8) {
          [step] = filter(speakingStepList, { questionId: item.id });
        } else {
          [step] = filter(writingStepList, { questionId: item.id });
        }
        if (step && step.answerId) {
          if (step.aiPigaiStatus === 'Marked') {
            score = step.aiPigaiScore;
          } else if (step.aiPigaiStatus === 'Committed') {
            score = '批改中';
            icon = require('./assets/pigai.gif');
          } else if (step.aiPigaiStatus === 'Failed') {
            score = '等待批改';
            icon = require('./assets/pigai_fail.png');
          } else if (step.aiPigaiStatus === 'NoAnswer') {
            score = '未作答';
            icon = require('./assets/pigai_fail.png');
          } else {
            score = '未提交批改';
          }
        } else {
          score = '未作答';
          icon = require('./assets/noanswer.png');
        }
        const material = { origin: item.stem };
        !material.origin && (material.origin = { paragraphs: [] });
        const { machinePigaiStatus, aiPigaiStatus } = step || {};
        let machineCorrectImage;
        if (['Committed', 'Reviewing'].indexOf(machinePigaiStatus) !== -1) {
          machineCorrectImage = require('components/report/assets/correct.gif');
        } else if (['Marked', 'Read'].indexOf(machinePigaiStatus) !== -1) {
          machineCorrectImage = require('components/report/assets/machine_correct_success.png');
        } else {
          machineCorrectImage = require('components/report/assets/machine_correct.png');
        }
        let aiCorrectImage;
        if (['Committed', 'Reviewing'].indexOf(aiPigaiStatus) !== -1) {
          aiCorrectImage = require('components/report/assets/correct.gif');
        } else if (['Marked', 'Read'].indexOf(aiPigaiStatus) !== -1) {
          aiCorrectImage = require('components/report/assets/machine_correct_success.png');
        } else {
          aiCorrectImage = require('components/report/assets/machine_correct.png');
        }
        return (
          <View>
            {
              isNumber(score) ?
                <View className={styles.questionPigaiNum}>
                  题目得分
                  <View className={styles.correctQuestionScore}>
                    {score < 0 ? 0 : score}/9分
                  </View>
                </View> :
                <View className={styles.questionPigaiNum}>
                  {
                    icon && <Image
                      className={styles.scoreIcon}
                      src={icon}
                    />
                  }
                  {score}
                </View>
            }
            <View key={item.id} className={[styles.questionBox, styles.questionMachineBox]} type="QuestionHtml">
              <View className={[styles.questionContent, styles.questionContent1]}>
                <View className={styles.question}>
                  <Article material={material.origin} isReport />
                </View>
                <View className={styles.questionIcon}>
                  {index + 1}
                </View>
                {
                  item.tag &&
                    <View className={styles.tag} style={{ marginTop: 0, marginBottom: '16px' }}>
                      {item.tag.name}
                    </View>
                }
              </View>
              <Button
                className={styles.button}
                text="答案解析"
                onClick={() => {
                  history.push(`/report/${mode}/${exerciseId}/${materialId}${search}`);
                }
                }
              />
              <Button
                className={[styles.button, styles.correctBtn]}
                leftIcon={aiCorrectImage}
                text="AI批改"
                onClick={() => this.handleMachine(step, item.subjectId, subjectPractice.name, 'ai')}
              />
              {report.from === 'smart' || source === 'smart' ? null : <Button
                className={[styles.button, styles.correctBtn]}
                leftIcon={this.handleForeignCorrect()}
                text="外教批改"
                onClick={() => {
                  if (!step.answerId || step.machinePigaiStatus === 'NoAnswer') {
                    Modal.show('ModalAlert', {
                      title: '提示',
                      width: 400,
                      buttons: [
                        { title: '知道了', style: styles.pay, onClick: undefined },
                      ],
                      component: (
                        /* eslint-disable */
                        <View className={styles.modalAlert}>
                          <Image
                            className={styles.modalAlertImage}
                            src={require('components/assets/default.png')}
                          />
                          <View className={styles.modalAlertText}>
                            你尚未作答，不支持批改哦~
                        </View>
                        </View>
                        /* eslint-enable */
                      ),
                      isReport: true,
                    });
                  } else {
                    let correctURL = `/correct/${step.subjectId}/${exerciseId}?title=${`${name || ''} - 智课`}&token=${token}&answerId=${step.answerId}`
                    if (source !== '') {
                      correctURL = `${correctURL}&source=${source}`
                    }
                    global.window.open(correctURL);
                  }
                }}
              />}
            </View>
          </View>
        )
      });
      return (
        <View key={item.id}>
          <View className={styles.title}>
            {title}
            {topic && topic.name && <View className={styles.topic}>{topic.name}</View>}
          </View>
          {questionsHtml}
        </View>
      );
    });
  }

  // 点击AI批改
  handleMachine = (step, subjectId, name, type) => {
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const token = tokenUrl || '';
    const status = get(step, pigaiTypeMap[type].statusKey)
    // 沿用以前machinePigaiStatus的noAnswer来判断是否答案为空
    if (!step.answerId || step.machinePigaiStatus === 'NoAnswer') {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '知道了', style: styles.pay, onClick: undefined },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/default.png')}
            />
            <View className={styles.modalAlertText}>
              你尚未作答，不支持批改哦~
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });
      return;
    } if (status === 'Marked') {
      pigaiTypeMap[type].openReportFunc({ subjectId, answerId: step.answerId, name, token })
    } else if (status === 'Committed') {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '知道了', style: styles.pay, onClick: undefined },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/success.png')}
            />
            <View className={styles.modalAlertText}>
              提交成功，小智正在努力批改中...
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });
    } else if (status === 'Uncommitted' || !status) {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '立即批改', style: styles.pay, onClick: () => pigaiTypeMap[type].submitFunc({ subjectId, answerId: step.answerId }) },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/success.png')}
            />
            <View className={styles.modalAlertText}>
              你还没有提交免费批改哦~
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });
    } else {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '取消', style: styles.close, onClick: undefined, class: 'gray' },
          { title: '重新批改', style: styles.pay, onClick: () => pigaiTypeMap[type].submitFunc({ subjectId, answerId: step.answerId }) },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/fail.png')}
            />
            <View className={styles.modalAlertText}>
              <span>Oops！批改失败了，点击<span style={{ color: '#39f' }}>重新批改</span>试试~</span>
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });
    }
  }

  // 渲染
  render() {
    const { logo } = this.props;
    const logoProp = getLogoProp({ image: logo.data.image, styles });
    return (
      <Scrollbar className={styles.container}>
        <View className={styles.header}>
          <View className={styles.headerText}>雅思模考测评报告</View>
          <View className={styles.headerText}>IELTS Mock Test Report - {this.props.report.name}</View>
          <Image {...logoProp} />
        </View>
        <View className={styles.content}>
          {this.generateScore()}
          {this.generateTab()}
          {this.generateData()}
          {this.generateAnswerDetail()}
          <Modal ref={modal => { Modal.instance = modal; }} />
        </View>
        <View className={styles.footer}>© 2011~2020 北京创新伙伴教育科技有限公司|京ICP备13047080号</View>
      </Scrollbar>
    );
  }
}

export default connect(state => ({ logo: state.logo }))(Reports);
