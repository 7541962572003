import React, { Component } from 'react';
import { createPromise } from 'utils/action';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import { View, Scrollbar, Image } from '@zhike/ti-ui';
import { Audio, SearchWords, Modal } from '@zhike/ti-component';
import AnswerParsing from 'components/report/common/answer_parsing';
import VideoParsing from 'components/report/common/video_parsing';
import RenderIndex from 'components/report/common/render_index';
import Collect from 'components/report/common/collect';
import { getAnswerOnly, postAIPigai } from 'common/apis';
import { css } from 'aphrodite';
import Notes from 'components/report/common/notes';
import axios from 'axios';
import Article from 'components/article';
import styles from './styles';

async function submitAIPigai({ subjectId, answerId }) {
  let url
  let subject
  if (Number(subjectId) === 8) {
    subject = 'speaking'
    url = postAIPigai[1].replace('@subject', subject)
  } else if (Number(subjectId) === 7) {
    subject = 'writing'
    url = postAIPigai[1].replace('@subject', subject)
  }
  const result = await axios({
    url,
    method: postAIPigai[0],
    headers: {
      'Content-Type': 'application/json',
      From: 1,
    },
    data: {
      subject,
      answerId,
    },
  });

  console.log('AI pigai result ', result)
  global.location.reload();
}

const pigaiTypeMap = {
  ai: {
    submitFunc: submitAIPigai,
    openReportFunc({ subjectId, answerId, name }) {
      global.window.open(`/machine-correctv2/${subjectId}/${answerId}?title=${name}`);
    },
    statusKey: 'aiPigaiStatus',
  },
}

// 阅读题目
export default class SpeakingReport extends Component {
  // 参数
  static propTypes = {
    practice: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    userGroup: PropTypes.number,
  };

  static defaultProps = {
    userGroup: 1,
  }

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      upButton: false,
      pigaiStatus: [],
    };
  }

  componentDidMount = () => {
    this.getPigaiStatus();
  }

  getPigaiStatus = async () => {
    const result = await axios({
      url: getAnswerOnly[1],
      method: getAnswerOnly[0],
      headers: {
        'Content-Type': 'application/json',
        From: 1,
      },
      params: {
        exerciseId: this.props.match.params.exerciseId,
        answerType: 'Speaking',
      },
    });
    const { records } = result.data.data;
    const pigaiStatus = [];
    records.map(record => {
      const { machinePigaiStatus, manualPigaiStatus, aiPigaiStatus, id: answerId } = record;
      let machinePigaiUrl;
      let manualPigaiPigaiUrl;
      const aiPigaiUrl = this.getPigaiIconByStatus(aiPigaiStatus)
      if (machinePigaiStatus === 'Marked') {
        machinePigaiUrl = require('./assets/machine_correct_success.png');
      } else if (machinePigaiStatus === 'Uncommitted') {
        machinePigaiUrl = require('./assets/machine_correct.png');
      } else if (machinePigaiStatus === 'Committed') {
        machinePigaiUrl = require('./assets/correct.png');
        setTimeout(() => {
          this.getPigaiStatus();
        }, 1000);
      } else if (machinePigaiStatus === 'Failed') {
        machinePigaiUrl = require('./assets/machine_correct_fail.png');
      } else {
        machinePigaiUrl = require('./assets/machine_correct.png');
      }
      if (manualPigaiStatus === 'Marked') {
        manualPigaiPigaiUrl = require('./assets/machine_correct_success.png');
      } else if (manualPigaiStatus === 'Uncommitted') {
        manualPigaiPigaiUrl = require('./assets/machine_correct.png');
      } else if (manualPigaiStatus === 'Committed') {
        manualPigaiPigaiUrl = require('./assets/correct.png');
      } else if (manualPigaiStatus === 'Failed') {
        manualPigaiPigaiUrl = require('./assets/foreign_correct_fail.png');
      }
      pigaiStatus.push({ machinePigaiUrl, manualPigaiPigaiUrl, aiPigaiUrl, machinePigaiStatus, manualPigaiStatus, aiPigaiStatus, answerId });
      return false;
    });
    this.setState({ pigaiStatus });
  }

  getPigaiIconByStatus(status) {
    let url
    switch (status) {
      case 'Marked':
        url = require('./assets/machine_correct_success.png');
        break
      case 'Committed':
        url = require('./assets/correct.png');
        break
      case 'Failed':
        url = require('./assets/foreign_correct_fail.png');
        break
      default:
        url = require('./assets/machine_correct.png'); // Uncommitted
        break
    }
    return url
  }

  // 切换 弹框显示与隐藏
  togglePigai() {
    this.setState({
      upButton: !this.state.upButton,
    });
  }
  hidePigai() {
    this.setState({
      upButton: false,
    });
  }

  handleAIMachine = answerId => {
    this.handlePigai(answerId, 'ai')
  }

  handlePigai = (answerId, type) => {
    const { pigaiStatus } = this.state;
    const statusKey = pigaiTypeMap[type].statusKey
    const status = get(find(pigaiStatus, item => item.answerId === answerId), statusKey);
    const { practice } = this.props;
    const { name, subjectId } = practice;
    if (!answerId) {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '知道了', style: styles.pay, onClick: undefined },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/default.png')}
            />
            <View className={styles.modalAlertText}>
              你尚未作答，不支持批改哦~
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });
    } else if (status === 'Marked') {
      pigaiTypeMap[type].openReportFunc({ subjectId, answerId, name })
    } else if (status === 'Committed') {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '知道了', style: styles.pay, onClick: undefined },
        ],
        component: (
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/success.png')}
            />
            <View className={styles.modalAlertText}>
              提交成功，小智正在努力批改中...
            </View>
          </View>
        ),
        isReport: true,
      });
    } else if (status === 'Uncommitted' || !status) {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '立即批改', style: styles.pay, onClick: () => pigaiTypeMap[type].submitFunc({ subjectId, answerId }) },
        ],
        component: (
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/success.png')}
            />
            <View className={styles.modalAlertText}>
              你还没有提交免费批改哦~
            </View>
          </View>
        ),
        isReport: true,
      });
    } else {
      Modal.show('ModalAlert', {
        title: '提示',
        width: 400,
        buttons: [
          { title: '取消', style: styles.close, onClick: undefined, class: 'gray' },
          { title: '重新批改', style: styles.pay, onClick: () => pigaiTypeMap[type]['submitFunc']({subjectId, answerId}) },
        ],
        component: (
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('components/assets/fail.png')}
            />
            <View className={styles.modalAlertText}>
              <span>Oops！批改失败了，点击<span style={{ color: '#39f' }}>重新批改</span>试试~</span>
            </View>
          </View>
        ),
        isReport: true,
      });
    }
  }

  async submitMachineCorrect(subjectId, answerId) {
    const { postMachinePigai } = this.props;
    await createPromise(postMachinePigai, {
      subject: subjectId === 8 ? 'speaking' : 'writing',
      answerId,
    });
    global.location.reload();
  }

  // 渲染
  render() {
    const { practice, match, userGroup, userGroupName } = this.props;
    const search = global.location.search; // eslint-disable-line
    const from = decodeURIComponent(search.match(new RegExp('[?#&]from=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const source = decodeURIComponent(search.match(new RegExp('[?#&]source=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const token = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const { upButton } = this.state;
    const questions = get(practice, 'questions');
    const { exerciseId } = match.params;
    const practiceId = get(practice, 'id');
    const conciseId = get(practice, 'conciseId');
    const subjectId = get(practice, 'subjectId');
    const name = get(practice, 'name');
    return (
      <Scrollbar
        className={styles.container}
        onScrollStart={() => { SearchWords.hide(); }}
      >
        <View className={styles.left}>
          <View className={styles.questionsTab}>
            <RenderIndex
              questions={questions}
              match={match}
              backgroundType={2}
            />
          </View>
          {
            questions.map((question, index) => {
              const id = get(question, 'materials.0.id');
              const direction = get(question, 'materials.0.direction'); // 指导语
              const stem = get(question, 'stem'); // 题目
              const { questionMaterialId } = match.params;
              if (questionMaterialId * 1 === id || (!questionMaterialId && index === 0)) {
                return (
                  <Scrollbar
                    className={styles.leftContent}
                    onScrollStart={() => { SearchWords.hide(); }}
                    key={id}
                  >
                    <View className={styles.article}>
                      {
                        direction && JSON.stringify(direction) !== '{}' &&
                        get(direction, 'paragraphs') &&
                        get(direction, 'paragraphs').length !== 0 &&
                        <View className={styles.titleBox}>
                          <View className={styles.text}>Direction:</View>
                          <View style={{ margin: '40px 0 0 20px' }}>
                            <Collect question={question} exerciseId={exerciseId} />
                          </View>
                        </View>
                      }
                      {
                        direction && JSON.stringify(direction) !== '{}' &&
                        get(direction, 'paragraphs') &&
                        get(direction, 'paragraphs').length !== 0 &&
                        <Article
                          material={direction}
                        />
                      }
                      <View className={styles.titleBox}>
                        <View className={styles.text}>Questions:</View>
                        {
                          (!direction || JSON.stringify(direction) === '{}' ||
                          !(get(direction, 'paragraphs') &&
                          get(direction, 'paragraphs').length !== 0)) &&
                          <View style={{ margin: '40px 0 0 20px' }}>
                            <Collect question={question} exerciseId={exerciseId} />
                          </View>
                        }
                      </View>
                      {
                        stem &&
                        <Article
                          material={stem}
                        />
                      }
                    </View>
                  </Scrollbar>
                  );
              }
              return false;
            })
          }
        </View>
        <View className={styles.rigth}>
          {
            questions.map((question, index) => {
              const id = get(question, 'materials.0.id');
              const analysis = get(question, 'materials.0.analysis'); // 答案解析
              const questionId = get(question, 'id');
              const videoTimeRangeId = get(question, 'videoTimeRangeId');
              const exampleSrc = get(question, 'materials.0.examples.0.audio.src');
              const exampleText = get(question, 'materials.0.examples.0.text');
              const answerId = get(question, 'materials.0.userAnswer.id');
              const audioSrc = get(question, 'materials.0.userAnswer.answer.src');
              const result = this.state.pigaiStatus[index];
              const { questionMaterialId } = match.params;
              if (questionMaterialId * 1 === id || (!questionMaterialId && index === 0)) {
                return (
                  <Scrollbar
                    className={styles.content}
                    onScrollStart={() => { SearchWords.hide(); }}
                    key={id}
                  >
                    {
                      audioSrc &&
                      <View>
                        <View className={styles.audioContainer} style={{ flexDirection: 'row !important', justifyContent: 'center', alignItem: 'center' }}>
                          <View className={styles.title} >我的音频</View>
                          <View className={styles.audioButton} onClick={() => this.togglePigai()} >
                            <span className={css(styles.pigaiText)}>批改</span>
                            <View className={upButton ? styles.audioButton1 : styles.audioButton2} />
                            {
                              upButton &&
                              <View>
                                <View
                                  className={styles.practice}
                                >
                                  <View
                                    onClick={() => this.handleAIMachine(answerId)}
                                    className={styles.practiceText}
                                  >
                                    <Image
                                      src={result.aiPigaiUrl}
                                      className={result.aiPigaiStatus === 'Committed' && styles.pigaiImage}
                                      style={{ width: '12px', heigth: '12px', marginRight: '8px', marginBottom: '12px' }}
                                    />
                                    <span className={css(styles.practiceText1)}>AI批改</span>
                                  </View>
                                  { ['smart', 'smartInside'].includes(from) ? null :
                                  <View
                                    onClick={() => {
                                      let correctURL = `/correct/${subjectId}/${exerciseId}?title=${`${name || ''} - 智课`}&answerId=${answerId}`
                                      if (token !== '') {
                                        correctURL = `${correctURL}&token=${token}`
                                      }
                                      if (source !== '') {
                                        correctURL = `${correctURL}&source=${source}`
                                      }
                                      global.window.open(correctURL);
                                    }}
                                    className={styles.practiceText}
                                  >
                                    <Image
                                      src={result.manualPigaiPigaiUrl}
                                      className={result.manualPigaiStatus === 'Committed' && styles.pigaiImage}
                                      style={{ width: '12px', heigth: '12px', marginRight: '8px' }}
                                    />
                                    <span>外教批改</span>
                                  </View>
                                  }
                                </View>
                                <View
                                  className={styles.practiceBodyWrapper}
                                  onClick={() => this.hidePigai()}
                                />
                              </View>
                            }
                          </View>
                        </View>
                        <Audio
                          src={audioSrc}
                        />
                      </View>
                    }
                    {
                      question && exerciseId > 0 &&
                      <View style={{ marginTop: '40px' }}>
                        <Notes question={question} />
                      </View>
                    }
                    {
                      analysis &&
                      analysis.paragraphs &&
                      analysis.paragraphs.length !== 0 &&
                      <AnswerParsing
                        answerAnalysis={analysis}
                        questionId={questionId}
                      />
                    }
                    {
                      conciseId && conciseId !== -1 && question &&
                      <VideoParsing
                        practiceId={practiceId}
                        startTip={videoTimeRangeId}
                        conciseId={conciseId}
                        userGroup={userGroup}
                        userGroupName={userGroupName}
                      />
                    }
                    {
                      exampleText &&
                      <View>
                        <View className={[styles.title, styles.title1]}>范例音频</View>
                        <Audio
                          src={exampleSrc}
                          text={exampleText}
                          materialType="exampleOriginal"
                        />
                      </View>
                    }
                  </Scrollbar>
                );
              }
              return false;
            })
          }
        </View>
      </Scrollbar>
    );
  }
}
