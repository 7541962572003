import React, { Component } from 'react';
import cookie from 'cookie';
import axios from 'axios';
import { Router, Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import Initial from 'containers/initial';
import Exercise from 'containers/exercise';
import MachineCorrect from 'containers/machine_correct';
import MachineCorrectV2 from 'containers/machine_correct_v2';
import Report from 'containers/report';
import Correct from 'containers/correct';
import Error from 'containers/error';
import PropTypes from 'prop-types';
import * as apis from '../common/apis';
import { getLogo } from '../actions/logo';
import { createPromise } from '../utils/action';

export const history = require('history').createBrowserHistory();

// 特殊处理
let RootContainer = class extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    getLogo: PropTypes.func.isRequired,
    logo: PropTypes.object.isRequired,
  }
  getToken = () => {
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let token;

    if (tokenUrl) {
      token = tokenUrl;
    } else {
      try {
        const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
        token = user.token; // eslint-disable-line
      } catch (e) {
        console.log(e, 'token');
      }
    }

    return token;
  }

  async componentWillMount() {
    // 动态获取站点logo
    await createPromise(this.props.getLogo);
  }

  async componentDidMount() {
    const token = this.getToken();
    if (token) {
      axios({
        url: apis.getUserStatus[1],
        method: apis.getUserStatus[0],
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token,
        },
        timeout: 20000,
      }).then(res => {
        const { data } = res;
        if (data.code === 0) {
          const { isCurrentPaid, isEverPaid } = data.data;
          typeof sa !== 'undefined' && sa.registerPage({ isCurrentPaid, isEverPaid });
        }

        typeof sa !== 'undefined' && sa.quick('autoTrack');
      }).catch(error => {
        console.log(error);
      });
    }
  }

  render() {
    // 神策打点
    if (this.pathName && this.pathName !== global.location.pathname) {
      typeof sa !== 'undefined' && sa.quick('autoTrackSinglePage'); // eslint-disable-line
    }
    this.pathName = global.location.pathname;
    return this.props.children;
  }
};

const mapStateToProps = state => ({
  logo: state.logo,
});

RootContainer = connect(mapStateToProps, {
  getLogo,
})(RootContainer);

const Routers = () => (
  <RootContainer>
    <Switch>
      <Route
        path="/error/:type/:retryUrl?"
        component={Error}
      />

      <Route
        path="/report/:mode/:exerciseId/:questionMaterialId?/:rankId?"
        component={Report}
      />

      <Route
        path="/init/:mode/:practiceId/:exerciseId/:operation?"
        component={Initial}
      />

      <Route
        path="/:mode/:practiceId/:exerciseId/:stepId/:operation?"
        component={Exercise}
      />

      <Route
        path="/machine-correctv2/:subjectId/:answerId"
        component={MachineCorrectV2}
      />

      <Route
        path="/machine-correct/:subjectId/:answerId"
        component={MachineCorrect}
      />

      <Route
        path="/correct/:subjectId/:exerciseId"
        component={Correct}
      />
    </Switch>
  </RootContainer>
);

const HotSwitch = hot(module)(Routers);

export const AppRouter = () => (
  <Router history={history}>
    <HotSwitch />
  </Router>
);
